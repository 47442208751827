<template>
    <div v-if="$can('adquisicion.menu.consultar.compra')">
        <ModuloConsultarIngreso
            :monedasimbolo="config.monedasimbolo"
            :userid="config.userid"
            :almacenid="config.almacenid"
        />
    </div>
</template>

<script>
    import ModuloConsultarIngreso from './Modulos/ModuloConsultarIngreso.vue'

    export default {
        props: [
            'usuario',
            'config'
        ],

        components: {
            ModuloConsultarIngreso
        }
    }
</script>
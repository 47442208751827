<template>
    <div>
        <a-row type="flex" :gutter="[2, 5]">
            <a-divider orientation="left">
				<span style="color:black; font-size:18px;">Compras</span> <span style="color:#9C9C9C; font-size:12px;">Visualiza tus comprobantes de compras</span>
			</a-divider>

            <a-col flex="1 1 100px">
                <a-button 
                    block 
                    type="primary" 
                    @click="openMenuQuery('compra', 1)" 
                    v-if="$can('adquisicion.menu.nueva.compra')"
                    >
                    <a-icon type="plus" /> Nueva Compra
                </a-button>
            </a-col>
            
            <a-col flex="1 1 100px">
                <a-button block type="dashed" @click="openModalConsultaOpciones()"><a-icon type="search" /> Consultar Opciones</a-button>
            </a-col>

            <a-col flex="1 1 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_inicio)" 
                    @change="onChangeFechaInicio" 
                    :locale="locale"
                    placeholder="Fecha Inicio"
                    style="width: 100%"
                />
            </a-col>

            <a-col flex="1 10 200px">
				<a-date-picker 
                    :value="utils.getDateFormat(consulta.fecha_final)" 
                    @change="onChangeFechaFinal" 
                    :locale="locale" 
                    placeholder="Fecha Final"
                    style="width: 100%"
                />
			</a-col>

            <a-col flex="1 1 350px">
                <a-input-search placeholder="Buscar Codigo de Compra" @search="onSearchIngreso">
                    <a-button type="primary" slot="enterButton">
                        Buscar
                    </a-button>
                </a-input-search>
            </a-col>

            <a-col :span="1">
                <a-button @click="obtenerListadoActual">
                    <a-icon type="reload" />
                </a-button>
            </a-col>
        </a-row>

		<br>
            
        <a-table 
                :columns="columns" 
                :data-source="list" 
                :pagination="paginate"
                :loading="loading"
                :ellipsis="true"
                size="small"
                :rowKey="record => record.compra_id"
                :scroll="{ x: 980 }"
                @change="handlePagination"
                >

                <template slot="codigo" slot-scope="item">
                    <span>{{ item.codigoCompra }}</span><br>
                    <template v-if="item.tipoingresoId === 2">
                        <span>{{ item.codigoControl }}</span><br>
                    </template>
                    <span style="font-size:11px; font-weight:700;">{{ item.tituloCompra }}</span>
                </template>

                <template slot="proveedor" slot-scope="item">
                    <span>{{ item.empresa }}</span><br>
                    <span style="font-size:10px; font-weight:700;">Usuario : {{ (item.usuario) ? item.usuario.usuario : 'No registrado' }}</span><br>
					<span v-if="item.estado_compra == 'APROBADO'" style="color:#188802;font-size: 11px;">APROBADO</span>
                    <span v-if="item.estado_compra == 'ANULADO'" style="color:rgb(192, 3, 3); font-weight:700; font-size: 11px;">ANULADO</span>
                </template>

                <template slot="plancuenta" slot-scope="item">
                    <span v-if="item.plancuenta_id === 11" style="color:#C44100; font-weight:700;">{{ item.nombre }}</span>
                    <span v-else>{{ item.nombre }}</span>
                </template>

                <template slot="fechacompra" slot-scope="item">
                    <center>{{ (item.fechaCompra) ? utils.formatOnlyDate(item.fechaCompra) : 'No registrado' }} {{ (item.horaIngreso) ? item.horaIngreso : '' }}</center>
                </template>

                <template slot="estado" slot-scope="item">
                    <template v-if="item.estado_compra === 'APROBADO'">
                        <a-tag color="#209004" v-if="item.estado_credito === 'NO APLICA'">
                            <span>APROBADO</span>
                        </a-tag>
                        <a-tag color="#f50" v-if="item.estado_credito === 'PENDIENTE'">
                            <span>PENDIENTE</span>
                        </a-tag>
                    </template>

                    <template v-if="item.estado_compra === 'ANULADO'">
                        <a-tag color="#C00303">
                            <span>ANULADO</span>
                        </a-tag>
                    </template>
                </template>

                <span slot="expandedRowRender" slot-scope="item" style="margin: 0">
                    <span style="font-weight:700; font-size:17px;">DATOS DE REGISTRO</span>
                    <a-row>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>COMPRA ID:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>CODIGO:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>SUCURSAL:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>PROVEEDOR:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>TIPO COMPRA:</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>TIPO INGRESO:</a-list-item></a-list>
                        </a-col>
                        <a-col :span="12">
                            <a-list size="small" bordered><a-list-item>{{ item.compra_id }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.codigoCompra }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.almacen.nombre }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.empresa }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.nombrePlanCuenta }}</a-list-item></a-list>
                            <a-list size="small" bordered><a-list-item>{{ item.nombreTipoIngreso }}</a-list-item></a-list>
                        </a-col>
                    </a-row>
                </span>

                <template slot="action" slot-scope="item">
                    <a-dropdown>
                        <a-menu slot="overlay">

                            <a-menu-item :key="buttonKeyVisualizarComprobante">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Visualizar Comprobante {{ item.codigoCompra }}</span>
                                    </template>
                                    <a href="#" @click.prevent="abrirModalIngreso(item)"><a-icon type="eye" :style="{ fontSize: '18px', color: '#006ccc' }"/> &nbsp;<strong>Visualizar Comprobante</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyDescargarReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Reporte PDF {{ item.codigoCompra }}</span>
                                    </template>
                                    <a href="#" @click.prevent="onClickPDF(item.compra_id)"><a-icon type="file-pdf" :style="{ fontSize: '18px', color: '#fa1d03' }"/> &nbsp;<strong>Comprobante en PDF</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <a-menu-item :key="buttonKeyImprimirReporte">
                                <a-tooltip placement="topLeft">
                                    <template slot="title">
                                        <span>Reporte Térmico {{ item.codigoCompra }}</span>
                                    </template>
                                    <a href="#" @click.prevent="imprimirTermico(item.compra_id)"><a-icon type="file-text" :style="{ fontSize: '18px', color: '#02c7c1' }"/> &nbsp;<strong>Comprobante Térmico</strong></a>
                                </a-tooltip>
                            </a-menu-item>

                            <template v-if="item.plancuentaID === 11">
                                <a-menu-item :key="buttonKeyImprimirCuentaPagar" v-if="$can('adquisicion.gestion.cuentas.por.pagar')">
                                    <a-tooltip placement="topLeft">
                                        <template slot="title">
                                            <span>Cuenta por Pagar : {{ item.codigoCompra }}</span>
                                        </template>
                                        <a href="#" @click.prevent="openModalCuentaPagar(item)"><a-icon type="profile" :style="{ fontSize: '18px', color: '#f50' }"/>
                                            <template v-if="item.estado_credito == 'PENDIENTE' && item.is_factura == 'RECIBO'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Cuenta por Pagar : {{ item.codigoCompra }}</strong>
                                            </template>
                                            <template v-if="item.estado_credito == 'PENDIENTE' && item.is_factura == 'FACTURA'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Cuenta por Pagar : {{ item.codigoCompra }}</strong>
                                            </template>
                                            <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'RECIBO'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Ver Cuotas : {{ item.codigoCompra }}</strong>
                                            </template>
                                            <template v-if="item.estado_credito == 'COBRADO' && item.is_factura == 'FACTURA'">
                                                &nbsp;<strong style="color:#f50; font-weight:700;">Ver Cuotas : {{ item.codigoCompra }}</strong>
                                            </template>
                                        </a>
                                    </a-tooltip>
                                </a-menu-item>
                            </template>

                            <template v-if="item.cuentaspagar.length === 0">
                                <template v-if="item.estado_compra == 'APROBADO'">
                                    <a-menu-item @click.once="AnularCompra(item.compra_id)" :key="buttonKeyAnularCompra" v-if="$can('adquisicion.anular.compra.realizada')">
                                        <a-tooltip placement="topLeft">
                                            <template slot="title">
                                                <span>Anular {{ item.codigoCompra }}</span>
                                            </template>
                                            <a-icon type="delete" :style="{ fontSize: '18px', color: '#cc2400' }"/><strong style="color:#cc2400; font-weight:700;">Anular Compra</strong>
                                        </a-tooltip>
                                    </a-menu-item>
                                </template>
                            </template>
                        </a-menu>
                        <a-button> Acciones <a-icon type="down" /> </a-button>
                    </a-dropdown>
                </template>
        </a-table>

        <a-modal
            v-model="modalDetalleIngreso"
            title="Detalle Ingreso"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="780"
            :zIndex="1050"
            >

            <div class="mb-12">
                <div class="row">
                    <div class="col-xs-12">
                        <div class="invoice-title">
                            <h3>CODIGO DE INGRESO</h3><h3 class="pull-right"><strong># {{ detalle.codigoCompra }}</strong></h3>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-xs-6">
                                <address>
                                <strong>Datos Registrados: </strong><br>
                                    Almacen / Sucursal: {{ detalle.almacen }}<br>
                                    Proveedor: {{ detalle.proveedor }}<br>
                                    Observaciones: {{ detalle.observacion }}
                                </address>
                            </div>
                            <div class="col-xs-6 text-right">
                                <address>
                                <strong>Datos Registrados:</strong><br>
                                    <span style="font-size:16px;">Nro de Documento: <strong>{{ detalle.nroDocumento }}</strong></span><br>
                                    Tipo Ingreso: {{ detalle.tipoingreso }}<br>
                                    Tipo Compra: {{ detalle.tipocompra }}<br>
                                    Fecha Ingreso: {{ detalle.fechaIngreso }}<br>
                                    Fecha Compra: {{ detalle.fechaCompra }}
                                </address>
                            </div>
                        </div>
                        <div class="panel panel-default">
                            <div class="panel-body">
                                <div class="col-xs-12 row">
                                    <strong>DATOS DE COMPRA CON FACTURA: </strong><br>
                                    Nro de FACTURA: <strong>{{ detalle.nrofactura }}</strong><br>
                                    Nro de AUTORIZACION: {{ detalle.nroautorizacion }}<br>
                                    CODIGO DE CONTROL: {{ detalle.codigocontrol }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
                <div class="row">
                    <div class="col-md-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">
                                <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                            </div>
                            <div class="panel-body">
                                <div class="box-body table-responsive no-padding">
                                    <table class="table table-condensed">
                                        <thead>
                                            <tr>
                                                <th><strong>ITEM</strong></th>
                                                <th class="text-center"><strong>ARTICULO</strong></th>
                                                <th class="text-center"><strong>CANT</strong></th>
                                                <th class="text-center"><strong>P.COMPRA</strong></th>
                                                <th class="text-center"><strong>DESC</strong></th>
                                                <th class="text-right"><strong>SUBTOTAL</strong></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in showModalIngresoListado" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td class="text-center">{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                <td class="text-center">{{ item.cantidad }}</td>
                                                <td class="text-center">{{ item.costo }}</td>
                                                <td class="text-center">{{ item.descuento }}</td>
                                                <td class="text-right">{{ item.subtotal }}</td>
                                            </tr>
                                        </tbody> 
                                        <tfoot>       
                                            <tr>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line"></td>
                                                <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                                <td class="thick-line text-right">{{ monedasimbolo }} {{ detalle.subTotalCompra }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Descuento</strong></td>
                                                <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.descuento }}</td>
                                            </tr>
                                            <tr>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line"></td>
                                                <td class="no-line text-center"><strong>Total</strong></td>
                                                <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.importeTotalCompra }}</td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalleIngreso = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <!-- GESTION CUENTA PAGAR -->
        <a-modal
            v-model="modalCuentaPagar"
            title="Gestion Cuentas por Pagar"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="1120"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <ModuloCuentaPorPagar
                    :monedasimbolo="monedasimbolo" 
                    :compraid="compra.compra_id"
                />
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalCuentaPagar = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <!-- MODAL CONSULTAS OPCIONES -->
        <a-modal
            v-model="modalConsultaOpciones"
            title="Consultar Compras"
            :closable="true"
            :destroyOnClose="false"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="350"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <a-row type="flex">
                    <a-col flex="1 1 200px" :span="24" v-if="$can('adquisicion.consultar.compra.por.sucursal')">
                        <label for="almacen" class="control-label">Sucursal:</label>
                        <a-select size="large" :value="consulta.almacen_id" @change="onChageAlmacen" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_almacenes" :key="index" :value="value.almacen_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="ingreso" class="control-label">Tipo de Ingreso:</label>
                        <a-select size="large" :value="consulta.tipoingreso_id" @change="onChageTipoIngreso" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_tipoingresos" :key="index" :value="value.tipoingreso_id"> {{ value.nombre }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="compra" class="control-label">Tipo de Compra:</label>
                        <a-select size="large" :value="consulta.plancuenta_id" @change="onChageTipoCompra" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_plancuenta" :key="index" :value="value.plancuenta_id"> {{ value.nombre_corto }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24" v-if="$can('adquisicion.consultar.compra.por.usuario')">
                        <label for="proveedor" class="control-label">Usuarios</label>
                        <a-select size="large" :value="consulta.usuario_id" @change="onChageUsuario" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_usuarios" :key="index" :value="value.usuario_id"> {{ value.usuario }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="proveedor" class="control-label">Proveedores:</label>
                        <a-select size="large" :value="consulta.proveedor_id" @change="onChageProveedor" show-search option-filter-prop="children" :filter-option="filterOptionProveedor" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_proveedores" :key="index" :value="value.proveedor_id"> {{ value.empresa }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label for="nitProveedor" class="control-label">NIT Proveedor :</label>
                        <a-select size="large" :value="consulta.proveedor_id" @change="onChageProveedorNit" show-search option-filter-prop="children" :filter-option="filterOptionProveedorNIT" style="width: 100%">
                            <a-select-option v-for="(value, index) in lista_nitproveedor" :key="index" :value="value.proveedor_id"> {{ value.documento }}</a-select-option>
                        </a-select>
                    </a-col>

                    <a-col flex="1 1 200px" :span="24">
                        <label class="control-label">Accion:</label>
                        <a-button size="large" type="primary" block @click="obtenerListadoActual">
                            <a-icon type="reload" /> Reestablecer Opciones Busqueda
                        </a-button>
                    </a-col>
                </a-row>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalConsultaOpciones = false">
                    Cerrar
                </a-button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 1055;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>
    </div>
</template>

<script>
    const columns = [
        { 
            id: 'compra_id', 
            title: 'Codigo', 
            dataIndex: '',
            key: 'codigo',
            scopedSlots: { customRender: 'codigo' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '20%'
        },
        { 
            id: 'compra_id',
            title: 'Proveedor', 
            dataIndex: '',
            key: 'proveedor',
            scopedSlots: { customRender: 'proveedor' },
            width: '20%',
            ellipsis: true
        },
        { 
            id: 'compra_id', 
            title: 'Tipo Compra', 
            dataIndex: 'plancuenta',
            scopedSlots: { customRender: 'plancuenta' }, 
            width: '13%'
        },
        { 
            id: 'compra_id',
            title: 'Subtotal', 
            dataIndex: 'subTotalCompra',
            width: '13%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'compra_id',
            title: 'Desc', 
            dataIndex: 'descuentoTotalCompra',
            width: '10%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'compra_id',
            title: 'Total', 
            dataIndex: 'importeTotalCompra',
            width: '12%',
            sortDirections: ['descend', 'ascend', false],
            sorter: true
        },
        { 
            id: 'compra_id',
            title: 'Estado', 
            dataIndex: '',
            key: 'estado',
            scopedSlots: { customRender: 'estado' }, 
            width: '10%'
        },
        { 
            id: 'compra_id', 
            title: 'F.Compra', 
            dataIndex: '',
            key: 'fechacompra',
            scopedSlots: { customRender: 'fechacompra' },
            sortDirections: ['descend', 'ascend', false],
            sorter: true,
            width: '15%'
        },
        { 
            id: 'compra_id',
            title: 'Acciones', 
            dataIndex: '',
            scopedSlots: { customRender: 'action' },
            width: '12%'
        }
    ]

	import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import { mapGetters, mapActions } from 'vuex'
    import ModuloCuentaPorPagar from './ModuloCuentaPorPagar.vue'

    import { 
        URI_ALMACEN_GET_LISTAR,
        URI_TIPO_INGRESO_LISTAR,
        URI_PLANCUENTA_LISTAR_CONSULTA_COMPRA,
        URI_PROVEEDOR_LISTAR,
        URI_PROVEEDOR_LISTAR_NIT,
        URI_USUARIO_LISTAR,
        URI_COMPRA_CONSULTAR_INGRESO,
        URI_COMPRA_BUSCAR,
        URI_COMPRA_BUSCAR_FECHA,
        URI_COMPRA_ANULAR
	} from '@/utils/constants'

    export default {

        props: [
            'monedasimbolo',
            'userid',
            'almacenid'
        ],

        components: {
            ModuloCuentaPorPagar
        },

        data () {
            return {
                dateFormat: 'YYYY-MM-DD',
				moment,
				locale,
                columns,
                utils,
				consulta: {
                    almacen_id: this.almacenid.toString(),
                    tipoingreso_id: 'Seleccionar',
                    plancuenta_id: 'Seleccionar',
					usuario_id: 'Seleccionar',
                    proveedor_id: 'Seleccionar',
					nit_proveedor: 'Seleccionar',
					fecha_inicio: '',
                    fecha_final: ''
				},
                lista_almacenes: [],
                lista_tipoingresos: [],
                lista_plancuenta: [],
				lista_usuarios: [],
                lista_proveedores: [],
                lista_nitproveedor: [],
                lista_productos: [],
                buttonKey: 1,
                showModal: false,
                detalle: {
                    almacen: '',
                    proveedor: '',
                    tipocompra: '',
                    tipoingreso: '',
                    codigoCompra: '',
                    fechaIngreso: '',
                    fechaCompra: '',
                    subTotalCompra: '',
                    descuento: '',
                    importeTotalCompra: '',
                    proveedorDireccion: '',
                    proveedorNit: '',
                    observacion: '',
					nroDocumento: ''
                },
                showModalIngresoListado: [],
                list: [],
                url: '',
                search: { keyword: '' },
                estadoTipoIngreso: 0,
                listadoingreso: [],
				disabledButtonUsuario: false,
				disabledButton: false,
                spinnerloading: false,
                buttonKeyDescargarReporte: 10,
                buttonKeyAnularCompra: 20,
                buttonKeyImprimirCuentaPagar: 30,
                buttonKeyVisualizarComprobante: 40,
                buttonKeyImprimirReporte: 50,
                modalDetalleIngreso: false,
                modalCuentaPagar: false,
                compra: {
                    compra_id: ''
                },
                paginate: {
                    pageSize: 10,
                    current: 1,
                    total: 0,
                    field: 'compra_id',
                    order: 'descend'
                },
                loading: false,
                modalConsultaOpciones: false
            }
        }, 

        created () {
            this.getAlmacenes()     
            this.getTipoIngreso()     
            this.getTipoCompras()
			this.getTipoUsuarios()
            this.getProveedores()
            this.getProveedoresNit()
            this.onCompraAlmacenDefault()
        },

        computed: {
            ...mapGetters('auth', ['user'])
        },

        methods: {
            openMenuQuery (name, query) {
				this.$router.push({
					name: name,
					params: { ventanaID: query }
				})
			},

            openModalConsultaOpciones () {
                this.modalConsultaOpciones = true
            },

            clearData () {
                this.consulta.tipoingreso_id = 'Seleccionar'
                this.consulta.plancuenta_id = 'Seleccionar'
				this.consulta.usuario_id = 'Seleccionar'
                this.consulta.proveedor_id = 'Seleccionar'
				this.consulta.nit_proveedor = 'Seleccionar'
				this.consulta.fecha_inicio = ''
                this.consulta.fecha_final = ''
            },

            obtenerListadoActual (value) {
                this.clearData()
                this.consultarIngresos()
            },

            onChangeFechaInicio (date, dateString) {
				this.consulta.fecha_inicio = dateString
			},

            onChangeFechaFinal (date, dateString) {
				this.consulta.fecha_final = dateString
                this.BuscarSalidaPorFechas()
			},

            filterOptionProveedor (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            filterOptionProveedorNIT (input, option) {
                return (
                    option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
                )
            },

            getAlmacenes () {
                axios.get(URI_ALMACEN_GET_LISTAR)
                .then(response => {
                    this.lista_almacenes = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },
            
            getTipoIngreso () {
                axios.get(URI_TIPO_INGRESO_LISTAR)
                .then(response => {
                    this.lista_tipoingresos = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getTipoCompras () {
                axios.get(URI_PLANCUENTA_LISTAR_CONSULTA_COMPRA)
                .then(response => {
                    this.lista_plancuenta = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getProveedores () {
                axios.get(URI_PROVEEDOR_LISTAR)
                .then(response => {
                    this.lista_proveedores = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getProveedoresNit () {
                axios.get(URI_PROVEEDOR_LISTAR_NIT)
                .then(response => {
                    this.lista_nitproveedor = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

			getTipoUsuarios () {
                axios.get(URI_USUARIO_LISTAR)
                .then(response => {
                    this.lista_usuarios = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            handlePagination (paginate, filters, sorter) {
                this.paginate = {
                    pageSize: paginate.pageSize,
                    current: paginate.current,
                    total: paginate.total,
                    field: (sorter.field) ? sorter.field : 'compra_id',
                    order: (sorter.order) ? sorter.order : 'descend'
                }
                this.consultarIngresos(this.paginate)
            },

            changePaginate (data) {
                this.paginate = {
                    pageSize: data.per_page,
                    current: data.current_page,
                    total: data.total,
                    field: 'compra_id',
                    order: 'descend'
                }
            },

            onCompraAlmacenDefault () {
                this.consultarIngresos()
            },

            consultarIngresos (paginate) {
                const formData = new FormData()
                const ecode = new EuropioCode()
				
                const consultaAlmacen = (this.consulta.almacen_id === 0) ? this.user.almacenID : this.consulta.almacen_id
                const consultaUsuario = (this.consulta.usuario_id === 'Seleccionar') ? '' : this.consulta.usuario_id
                formData.append('almacenID', (this.$can('adquisicion.consultar.compra.por.sucursal')) ? consultaAlmacen : this.user.almacenID)
                formData.append('usuarioID', (this.$can('adquisicion.consultar.compra.por.usuario')) ? consultaUsuario : this.user.usuario_id)
            
                formData.append('tipoingresoID', (this.consulta.tipoingreso_id === 'Seleccionar') ? 0 : this.consulta.tipoingreso_id)
                formData.append('plancuentaID', (this.consulta.plancuenta_id === 'Seleccionar') ? 0 : this.consulta.plancuenta_id)
                formData.append('proveedorID', (this.consulta.proveedor_id === 'Seleccionar') ? '' : this.consulta.proveedor_id)
                formData.append('proveedorNIT', (this.consulta.nit_proveedor === 'Seleccionar') ? '' : this.consulta.nit_proveedor)
                formData.append('paginate', (paginate) ? ecode.encode(JSON.stringify(paginate)) : ecode.encode(JSON.stringify(this.paginate)))

                axios.post(URI_COMPRA_CONSULTAR_INGRESO, formData)
                .then(response => {
                    this.list = response.data.data
                    this.changePaginate(response.data)
                    // this.consulta.almacen_id = Object.values(this.list).filter(x => typeof x !== 'undefined').shift().almacenId.toString()
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            onChageAlmacen (value) {
                this.consulta.almacen_id = value
                this.estadoTipoIngreso = 0
                this.consultarIngresos()
            },

            onChageTipoIngreso (value) {
                this.consulta.tipoingreso_id = value
                this.consultarIngresos()
            },

            onChageTipoCompra (value) {
                this.consulta.plancuenta_id = value
                this.estadoTipoIngreso = 0
                this.consultarIngresos()
            },

			onChageUsuario (value) {
                this.consulta.usuario_id = value
                this.estadoTipoIngreso = 0
                this.consultarIngresos()
            },

            onChageProveedor (value) {
                this.consulta.proveedor_id = value
                this.estadoTipoIngreso = 0
                this.consultarIngresos()
            },

            onChageProveedorNit (value) {
                this.consulta.proveedor_id = value
                this.estadoTipoIngreso = 0
                this.consultarIngresos()
            },

            onSearchIngreso (value) {
                this.search.keyword = value
                this.buscarIngresoButton()
            },

            buscarIngresoButton (paginate) {           
                this.spinnerloading = true
				const ecode = new EuropioCode()
				const formData = new FormData()
                const consultaAlmacen = (this.consulta.almacen_id === 0) ? this.user.almacenID : this.consulta.almacen_id
                const consultaUsuario = (this.consulta.usuario_id === 'Seleccionar') ? this.user.usuario_id : this.consulta.usuario_id
                formData.append('almacenID', (this.$can('adquisicion.consultar.compra.por.sucursal')) ? consultaAlmacen : this.user.almacenID)
                formData.append('usuarioID', (this.$can('adquisicion.consultar.compra.por.usuario')) ? consultaUsuario : this.user.usuario_id)
				
                formData.append('keyword', ecode.encode(this.search.keyword))
                formData.append('paginate', (paginate) ? ecode.encode(JSON.stringify(paginate)) : ecode.encode(JSON.stringify(this.paginate)))

            	axios.post(URI_COMPRA_BUSCAR, formData)
                .then(response => {
            	    this.list = response.data.data
                    this.spinnerloading = false
                    this.changePaginate(response.data)
            	})
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            BuscarSalidaPorFechas (paginate) {
				this.spinnerloading = true
                const ecode = new EuropioCode()
                const formData = new FormData()

                const consultaAlmacen = (this.consulta.almacen_id === 0) ? this.user.almacenID : this.consulta.almacen_id
                const consultaUsuario = (this.consulta.usuario_id === 'Seleccionar') ? this.user.usuario_id : this.consulta.usuario_id
                formData.append('almacenID', (this.$can('adquisicion.consultar.compra.por.sucursal')) ? consultaAlmacen : this.user.almacenID)
                formData.append('usuarioID', (this.$can('adquisicion.consultar.compra.por.usuario')) ? consultaUsuario : this.user.usuario_id)
                
                formData.append('fecha_inicio', (this.consulta.fecha_inicio === '') ? 0 : ecode.encode(this.consulta.fecha_inicio))
                formData.append('fecha_final', (this.consulta.fecha_final === '') ? 0 : ecode.encode(this.consulta.fecha_final))
                formData.append('paginate', (paginate) ? ecode.encode(JSON.stringify(paginate)) : ecode.encode(JSON.stringify(this.paginate)))

                axios.post(URI_COMPRA_BUSCAR_FECHA, formData)
                .then(response => {
					this.list = response.data.data
                    this.spinnerloading = false
                    this.changePaginate(response.data)
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            abrirModalIngreso (item) {
                console.log(item)
                this.spinnerloading = true
                this.showModalIngresoListado = []
                this.detalle = {}
                setTimeout(function () {
                    this.detalle.almacen = item.almacen.nombre
                    this.detalle.proveedor = item.nombreProveedorNit
                    this.detalle.tipocompra = item.nombrePlanCuenta
                    this.detalle.tipoingreso = item.nombreTipoIngreso
                    this.detalle.codigoCompra = item.codigoCompra
                    this.detalle.fechaIngreso = item.fechaIngreso
                    this.detalle.fechaCompra = item.fechaCompra
                    this.detalle.subTotalCompra = item.subTotalCompra
                    this.detalle.descuento = item.descuentoTotalCompra
                    this.detalle.importeTotalCompra = item.importeTotalCompra
                    this.detalle.observacion = item.observacionCompra
                    this.detalle.nroDocumento = item.nroDocumento
                    this.detalle.nroautorizacion = item.autorizacionCompra
                    this.detalle.nrofactura = item.facturaCompra
                    this.detalle.codigocontrol = item.codigoControl
                    this.showModalIngresoListado = item.listacompras

                    this.modalDetalleIngreso = true
                    this.spinnerloading = false
                }.bind(this), 500)
            },
			
			AnularCompra (id) {
                Swal.fire({
                    title: 'Desea Anular la Compra?',
                    text: 'Tome en cuenta que al anular la compra, las cantidades saldran del inventario y si el stock es menor a la salida se registrara con valor 0, por lo tanto debera registrar la compra para que tenga stock en el inventario.',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Si, Proceder Ahora',
                    cancelButtonClass: 'btn btn-danger',
                    confirmButtonClass: 'btn btn-success',
                    cancelButtonText: 'No, Proceder!'
                }).then((result) => {
                    if (result.value) {
						this.spinnerloading = true
                        const formData = new FormData()
                        formData.append('compraID', id)
                            
                        axios.post(URI_COMPRA_ANULAR, formData)
                        .then(response => {
                            if (response.status === 201) {
								this.onCompraAlmacenDefault()
								this.spinnerloading = false
                                utils.openNotificationWithIcon('info', 'Mensaje : ', 'Atencion!!!: Todos los productos han sido (descontados/salieron) del inventario por tanto si tenia un stock menor a la salida este queda con valor 0, se recomienda volver a registrar una compra para que tenga stock en el inventario.', 'topRight')
                            }
                        })
                        .catch(error => {
                            utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                        })
                    } else if (result.dismiss === Swal.DismissReason.cancel) {
                        Swal.fire(
                            'No Realizado',
                            'No has realizado ninguno :(',
                            'error'
                        )
                        this.buttonKey++
                    }
                })
            },

            openModalCuentaPagar (item) {
                this.compra.compra_id = item.compra_id
                this.modalCuentaPagar = true
            },

            onClickPDF (compraID) {
                utils.openReciboMobile(`/reporte/compra/reporte/carta/${compraID}`)
            },

            imprimirTermico (compraID) {
                utils.openReciboMobile(`/reporte/compra/termica/${compraID}`)
            }
        }
    }
</script>
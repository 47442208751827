<template>
    <div class="mb-12">

        <div class="col-md-12" style="top: -7px;">
            <a-button 
                v-if="detalle.estado_credito === 'PENDIENTE'" 
                type="primary" 
                @click="openModalRealizarTransaccion"
                > 
                <a-icon type="plus" />Realizar nuevo pago
            </a-button>

            <a-button 
                @click="openModalDetalle"
                > 
                <a-icon type="file-search" />Detalle compra
            </a-button>
        </div>

        <hr>

        <a-row type="flex" :gutter="16">
            <a-col flex="1 1 100px">
                <a-card title="Resumen de Compra">
                    <table>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">CODIGO COMPRA:</td>
                            <td>{{ detalle.codigoCompra }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">FECHA REGISTRO:</td>
                            <td>{{ detalle.fechaIngreso }}  {{ detalle.horaIngreso }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">PROVEEDOR:</td>
                            <td>{{ detalle.proveedor.empresa }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">SUCURSAL:</td>
                            <td>{{ detalle.almacen.nombre }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">MONTO ORIGINAL:</td>
                            <td>{{ detalle.simbolo }}  {{ detalle.importeTotalCompra }}</td>
                        </tr>
                        <tr>
                            <td style="width:20px;"></td>
                            <td style="width:200px;">SALDO ACTUAL:</td>
                            <td>{{ detalle.simbolo }}  {{ detalle.saldoActual }}</td>
                        </tr>             
                    </table>
                </a-card>
            </a-col>

            <a-col flex="1 1 400px">
                <a-card title="Detalle de Pagos">
                    <div class="table-responsive no-padding">
                        <table class="table table-bordered table-striped">
                            <thead>
                                    <tr>
                                        <th>Codigo Cuenta</th>
                                        <th>Fecha</th>
                                        <th>Hora</th>
                                        <th>Monto</th>
                                        <th>Saldo</th>
                                        <th></th>
                                    </tr>
                            </thead>
                            <tbody>
                                    <tr v-for="(item, index) in listacuentapagar" :key="index">
                                        <td>{{ item.codigoCuentaPagar }}</td>
                                        <td>{{ item.fechaPagar }}</td>
                                        <td>{{ item.horaPagar }}</td>
                                        <td>{{ item.montoCanceladoPagar }}</td>
                                        <td>{{ item.saldoActualPagar }}</td>
                                        <th>
                                            <a-button type="dashed" block @click.prevent="reporteCarta(item.cuentapagar_id)" style="font-size:12px;">
                                                <a-icon type="file-pdf" :style="{ fontSize: '15px', color: '#fa1d03' }"/>Comprobante
                                            </a-button>
                                        </th>
                                    </tr>
                            </tbody>
                        </table>
                    </div>
                </a-card>
            </a-col>
        </a-row>

        <!-- DETALLE COMPRA -->
        <a-modal
            v-model="modalDetalle"
            title="Detalle Compra"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="760"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <div class="row">
                                <div class="col-md-12">
                                    <div class="invoice-title">
                                        <h3>CODIGO DE INGRESO</h3><h3 class="pull-right"><strong># {{ detalle.codigoCompra }}</strong></h3>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <address>
                                            <strong>Datos Registrados: </strong><br>
                                                Almacen / Sucursal : {{ detalle.almacen.nombre }}<br>
                                                Proveedor : {{ detalle.proveedor.empresa }}<br>
                                                Direccion : {{ detalle.proveedor.direccion }}<br>
                                                NIT / CI : {{ detalle.proveedor.documento }}<br>
                                                Observaciones : {{ detalle.observacionCompra }}
                                            </address>
                                        </div>
                                        <div class="col-xs-6 text-right">
                                            <address>
                                            <strong>Datos Registrados:</strong><br>
                                                Tipo de Ingreso : {{ detalle.tipoingreso.nombre }}<br>
                                                Tipo de Compra : {{ detalle.plancuenta.nombre }}<br>
                                                Fecha de Ingreso : {{ detalle.fechaIngreso }}<br>
                                                Fecha de Compra : {{ detalle.fechaCompra }}
                                            </address>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="panel panel-default">
                                        <div class="panel-heading">
                                            <h3 class="panel-title"><strong>Detalle Registrado</strong></h3>
                                        </div>
                                        <div class="panel-body">
                                            <div class="box-body table-responsive no-padding">
                                                <table class="table table-condensed">
                                                    <thead>
                                                        <tr>
                                                            <th><strong>ITEM</strong></th>
                                                            <th class="text-center"><strong>ARTICULO</strong></th>
                                                            <th class="text-center"><strong>CANT</strong></th>
                                                            <th class="text-center"><strong>P.COMPRA</strong></th>
                                                            <th class="text-center"><strong>DESC</strong></th>
                                                            <th class="text-right"><strong>SUBTOTAL</strong></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item, index) in detalle.listacompras" :key="index">
                                                            <td>{{ index + 1 }}</td>
                                                            <td>{{ item.codigo }} {{ item.nombre }} {{ item.medida }}</td>
                                                            <td class="text-center">{{ item.cantidad }}</td>
                                                            <td class="text-center">{{ item.costo }}</td>
                                                            <td class="text-center">{{ item.descuento }}</td>
                                                            <td class="text-right">{{ item.subtotal }}</td>
                                                        </tr>
                                                    </tbody> 
                                                    <tfoot>       
                                                        <tr>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line"></td>
                                                            <td class="thick-line text-center"><strong>Subtotal</strong></td>
                                                            <td class="thick-line text-right">{{ monedasimbolo }} {{ detalle.subTotalCompra }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line text-center"><strong>Descuento</strong></td>
                                                            <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.descuentoTotalCompra }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line"></td>
                                                            <td class="no-line text-center"><strong>Total</strong></td>
                                                            <td class="no-line text-right">{{ monedasimbolo }} {{ detalle.importeTotalCompra }}</td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
            </div>
                    
            <template slot="footer">
                <a-button key="back" @click="modalDetalle = false">
                    Cerrar Modal
                </a-button>
            </template>
        </a-modal>

        <!--REALIZANDO PAGOS-->
        <a-modal
            v-model="modalRealizarTransaccion"
            title="REALIZAR NUEVO PAGO"
            :closable="true"
            :destroyOnClose="true"
            :maskClosable="false"
            :dialog-style="{ top: '5px' }"
            :width="480"
            :zIndex="1050"
            >

            <div class="row mb-12">
                <label for="0" style="font-size:11px; font-weight:700;">
                    <input type="radio" v-model="selectedMoney" v-bind:value="0">
                    {{ monedaliteral }}&nbsp;&nbsp; 
                </label>
                <label :for="i" v-for="(aalabel, i) in listMoney" :key="i" style="font-size:11px; font-weight:700;">
                    <input type="radio" v-model="selectedMoney" v-bind:value="aalabel.tipocambio_id">
                    {{ aalabel.nombre }}&nbsp;&nbsp; 
                </label><br>

                <a-descriptions :title="`Código : ${detalle.codigoCompra}`" layout="vertical">
                    <a-descriptions-item label="MONTO ORIGINAL">
                        {{ detalle.simbolo }} {{ detalle.importeTotalCompra }}
                    </a-descriptions-item>
                    <a-descriptions-item label="SALDO ACTUAL">
                        {{ detalle.simbolo }} {{ detalle.saldoActual }}
                    </a-descriptions-item>
                </a-descriptions>

                <div class="row">
                    <div class="col-md-12">
                        <label class="d-sm-block">Importe a Pagar:</label>
                        <strong>
                            <a-input-number 
                                size="large" 
                                :min="0" 
                                :max="100000" 
                                :default-value="cuenta.importe" 
                                @keypress="onlyNumber" 
                                @change="onChangeImporte"
                                style="width:100%; height:50px; font-size:27px;  border-width:2px; border-color:orange; border-style:solid;" 
                                aria-placeholder="0.00"
                            />
                        </strong>
                    </div>
                </div>
            </div>
                    
            <template slot="footer">
                <button 
                    type="button" 
                    class="btn btn-block" 
                    style="background-color:#0048ce; color:#ffffff; font-size:20px; font-weight: 700; margin-left: 0px;" 
                    @click.once="realizarTransaccion()" 
                    :key="buttonKey"
                    >
                    <i class="fa fa-check"></i> PAGAR AHORA 
                </button>
            </template>
        </a-modal>

        <div v-if="spinnerloading == true" class="col-md-12" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background: rgba(0, 0, 0, 0.5); z-index: 99999 !important;">
            <div style="margin-top: 250px;">
                <vue-simple-spinner :line-size="10" size="massive" text-fg-color="#f3f3f3" message="Procesando Informacion..."></vue-simple-spinner>
                <br><br>
                <center><button type="button" class="btn btn-warning btn-sm" title="Cancelar" @click="spinnerloading = false"><i class="fa fa-stop"></i> Cerrar Ventana de Espera</button></center>
            </div>
        </div>

    </div>
</template>

<script>
    import locale from 'ant-design-vue/es/date-picker/locale/es_ES'
	import moment from 'moment'
	import 'moment/locale/es'
	import * as utils from '@/utils/utils'
	import { EuropioCode } from '@/utils/europiocode'
	import axios from '@/config/axios/axios'
	import Swal from 'sweetalert2'
    import numeral from 'numeral'

    import { 
		URI_PRINCIPAL_TIPO_CAMBIO,
        URI_CUENTAPAGAR_GUARDAR,
        URI_CUENTAPAGAR_LISTAR_COBRAR,
        URI_CUENTAPAGAR_LISTAR
	} from '@/utils/constants'

    export default {

        props: [
            'compraid', 
            'monedasimbolo'
        ],

        data () {
            return {
                detalle: [],
                buttonKey: 10,
                clicked: false,
                cuenta: {
                    importe: 0
                },
                listacuentapagar: [],
				estadoCobro: false, 
				selectedMoney: 0,
				listMoney: [],
                modalDetalle: false,
                modalRealizarTransaccion: false,
                monedaliteral: '',
                spinnerloading: false
            }
        }, 

        created () {
            this.getListado()
			this.getListaPagar()
			this.getMoney()
        },

        methods: {

            onlyNumber ($event) {
				const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
				if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
					$event.preventDefault()
				}
			},

            onChangeImporte (value) {
				this.cuenta.importe = value
			},

            openModalDetalle () {
                this.modalDetalle = true
            },

            openModalRealizarTransaccion () {
                this.modalRealizarTransaccion = true
            },

			getMoney () {
                axios.get(URI_PRINCIPAL_TIPO_CAMBIO)
                .then(response => {
                    this.listMoney = response.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
            },

            getListado () {
                const formData = new FormData()
                formData.append('compraID', this.compraid)
                
                axios.post(URI_CUENTAPAGAR_LISTAR_COBRAR, formData)
                .then(response => {
                    this.detalle = response.data.data
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })

                this.showModal = true
            },

            getListaPagar () {
                this.spinnerloading = true
                const formData = new FormData()
                formData.append('compraID', this.compraid)

                axios.post(URI_CUENTAPAGAR_LISTAR, formData)
                .then(response => {
                    this.listacuentapagar = response.data.data
                    this.spinnerloading = false
                })
                .catch(error => {
                    utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                })
                this.showModal = true
            },

            realizarTransaccion () {
                const MontoOriginal = parseFloat(numeral(this.detalle.importeTotalCompra).value())
				const ImportePagar = parseFloat(numeral(this.cuenta.importe).value())

                if (ImportePagar > MontoOriginal) {
                    Swal.fire('No Realizado', 'El Monto a Pagar es mayor al Monto Total...', 'error')
                    this.buttonKey++
                } else if (ImportePagar <= 0) {
                    Swal.fire('No Realizado', 'El importe a Pagar es igual a Cero, Ingrese un Mayor Monto...', 'error')
                    this.buttonKey++
                } else {
                    Swal.fire({
                        title: 'Desea Agregar y Guardar?',
                        text: 'Tome en cuenta que debera registrar con cuidado los campos ingresados y no debe contener datos duplicados en el sistema.',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Si, Proceder Ahora',
                        cancelButtonClass: 'btn btn-danger',
                        confirmButtonClass: 'btn btn-success',
                        cancelButtonText: 'No, Proceder!'
                    }).then((result) => {
                        if (result.value) {
                            this.spinnerloading = true
                            const ecode = new EuropioCode()
                            const formData = new FormData()
                            formData.append('compraID', this.compraid)
                            formData.append('montoCanceladoPagar', ecode.encode(this.cuenta.importe.toString()))
                            formData.append('montoOriginal', ecode.encode(MontoOriginal.toString()))
                            formData.append('tipocambio', parseInt(this.selectedMoney))

                            axios.post(URI_CUENTAPAGAR_GUARDAR, formData)
                            .then(response => {
                                if (response.status === 201) {
                                    this.cuenta.importe = '0.00'
                                    this.getListado()
                                    this.getListaPagar()
                                    this.modalRealizarTransaccion = false
                                    this.spinnerloading = false
                                    Swal.fire('Éxito!', 'Has Realizado Correctamente el Pago.', 'success')
                                }
                            })
                            .catch(error => {
                                utils.openNotificationWithIcon('error', 'Mensaje : ', utils.showErrors(error.response.data), 'topRight')
                            })
                        } else if (result.dismiss === Swal.DismissReason.cancel) {
                            Swal.fire(
                                'No Realizado',
                                'No has realizado ninguno :(',
                                'error'
                            )
                        }
                    })
				}

                this.buttonKey++
            }
        },

        computed: {
            isDisabled () {
                if (this.estadocuentapagar === 'desactivado') {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style>
        .example-modal .modal {
          position: relative;
          top: auto;
          bottom: auto;
          right: auto;
          left: auto;
          display: block;
          z-index: 1;
        }

        .example-modal .modal {
          background: transparent !important;
        }
</style>